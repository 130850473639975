#app-wrapper
  display: flex
  flex-direction: column
  flex: 1 1 0
  min-height: 100vh
  background: var(--grey-99)
  color: var(--white)

  #app-content
    display: flex
    flex-direction: column
