.subscribe-fob
  margin-bottom: 5rem

  .title
    font-size: 2.5rem
    font-weight: 700
    margin-bottom: 0

  .subtitle
    margin-bottom: 1.5rem

  .newsletter-box
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .form-input
    width: 100%
    max-width: 600px
    display: flex
    justify-content: center
    align-items: center

    input
      border: thin solid var(--black)

    .btn-subscribe
      margin-left: 1rem
      background: none

  @media screen and ( max-width: 768px )
    .title
      font-size: 1.8rem

  @media screen and ( max-width: 480px )
    margin-bottom: 2.5rem
    .title
      font-size: 1.5rem
