.truncate
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap

// container
.container
	width: 80%
	max-width: 1280px
	padding: 0 0.937rem
	margin: 0 auto

	@media screen and ( max-width: 880px )
		width: 90%
		padding: 0 1.25rem

	@media screen and ( max-width: 640px )
		width: 100%
		padding: 0 1.25rem

// flex
.d-flex
	display: flex

.d-inline-flex
	display: inline-flex

.flex-column
	flex-direction: column

.flex-wrap
	flex-wrap: wrap

.justify-content-center
	justify-content: center

.justify-content-space-between
	justify-content: space-between

.justify-content-space-evenly
	justify-content: space-evenly

.align-items-center
	align-items: center

.align-items-start
	align-items: flex-start

// gap flex
.gap-10
	gap: 0.625rem

.error-message
	font-size: 0.812rem
	color: var(--pink)

.cursor-pointer
	cursor: pointer

// block size
.full-width
	width: 100%

.link-black-underline
	text-decoration: underline !important
