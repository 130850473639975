.modal-preview-image
  width: auto !important
  max-width: 800px !important
  text-align: center
  top: 10rem !important

  .md-content
    display: inline-block

    video
      width: 100%
      height: 100%

  .ant-modal-content, .ant-modal-body
    padding: 0 !important

  .ant-modal-content
    display: inline-block
    border-radius: 1rem
    margin: 0 1.25rem
    overflow: hidden
