.ant-tag
  padding: 0.4rem 0.875rem !important
  border-radius: 5.625rem
  border: thin solid var(--grey-50) !important
  border-radius: 5.625rem !important
  color: var(--pink) !important
  font-weight: 500
  font-size: 1rem !important
  margin: 0 0.625rem 0.625rem 0 !important

.ant-tag-checkable-checked
  color: var(--pink-dark-10) !important
  background-color: var(--pink) !important
  border: thin solid var(--pink) !important

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon, .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-list-item-error .ant-upload-text-icon > .anticon
  color: var(--green) !important

.ant-upload-list-item:hover
  .ant-upload-list-item-info
    background-color: transparent !important
    cursor: pointer

// ant-slider
.ant-slider
  &:hover
    .ant-slider-track
      background-color: var(--green) !important

.ant-slider-handle
  border-color: var(--green) !important

// ant-notification
.ant-notification-notice
  width: 19.375rem !important
  padding: 0.8rem 1rem !important
  border-radius: 0.625rem !important

.ant-notification-notice-error
  background: var(--white) !important
  .ant-notification-notice-message
    color: var(--ant-error-color) !important

.ant-notification-notice-success
  background: var(--white) !important
  .ant-notification-notice-message
    color: var(--ant-success-color) !important

.ant-notification-notice-warning
  background: var(--yellow-notice) !important

.ant-notification-notice-icon
  display: none !important

.ant-notification-notice-message
  margin-left: 0 !important
  font-weight: 700

.ant-notification-notice-description
  margin-left: 0rem !important
  font-weight: 400

  .txn-link
    span
      text-decoration: underline
      color: var(--ant-primary-color) !important

.ant-notification-notice-close-icon
  color: var(--grey-80) !important

// ant modal
.ant-modal
  .ant-modal-header
    padding: 1rem !important

    .ant-modal-title
      font-size: 1.125rem
      font-weight: 700
  .ant-modal-body
    padding: 1rem !important

  .ant-modal-footer
    display: none !important

// ant-select-selection
.ant-select-selection
  background-color: green

.ant-select-dropdown
  border-radius: 0.5rem !important
  background-color: var(--grey-70) !important
  .ant-select-item-option-disabled.ant-select-item.ant-select-item-option
    background-color: var(--grey-70)
    color: #bfbfbf
    .ant-select-item.ant-select-item-option
      color: var(--white)
    .ant-select-item-option-active:not(.ant-select-item-option-disabled)
      background-color: var(--grey-80)
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      background-color: var(--grey-80)

//ant-pagination
.ant-pagination-item-active, .ant-pagination-item:hover
  border-color: var(--black) !important

.ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link, .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link
  color: var(--black) !important
  border-color: var(--black) !important
