*
    margin: 0
    padding: 0
    box-sizing: border-box

html
    scroll-behavior: smooth
    font-size: 16px

body
    font-family: 'Noto Sans JP', sans-serif !important
    font-weight: 400
    color: var(--black)

a
    color: var(--black) !important
    text-decoration: none

button
    outline: none
    border: none
    cursor: pointer
    text-transform: capitalize
    &:disabled
        opacity: 0.7
        cursor: not-allowed !important

ul,
li
    list-style: none

img
    max-width: 100%

input
    width: 100%
    min-height: 2.5rem
    padding: 0.625rem
    border: none
    overflow: hidden

    &:focus
        outline: none
        box-shadow: none

select
    &:focus
        outline: none

textarea
    width: 100%
    min-height: 9.125rem
    max-height: 200px
    padding: 1rem
    border: none
    border-radius: 1rem
    overflow: hidden

    &:focus
        outline: none
        box-shadow: none

.ant-spin-spinning
    height: 1.25rem

    .ant-spin-dot-item
        background-color: var(--white)

.ant-checkbox-wrapper
    .ant-checkbox-inner
        background-color: var(--black)
        border: none

        &:after
            width: 0.625rem
            height: 1.125rem

    .ant-checkbox-checked
        &:after
            border: none

        .ant-checkbox-inner
            background-color: var(--white)

.custom-checkbox
    width: 2rem
    height: 2rem
    position: relative
    cursor: pointer
    margin-right: 1rem
    border-radius: 0.625rem
    overflow: hidden

    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    .checkmark
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: var(--black)
        cursor: pointer

        &::after
            content: ""
            position: absolute
            display: none

    input:checked ~ .checkmark
        background: var(--green)

    input:checked ~ .checkmark:after
        display: block

    .checkmark:after
        left: 50%
        top: 50%
        transform: translate(-50%, -50%) rotate(45deg)
        width: 0.625rem
        height: 1rem
        border: solid var(--white)
        border-width: 0 0.25rem 0.25rem 0
