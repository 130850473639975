.base-button
  min-width: 9.375rem
  padding: 0.875rem 2.7rem
  font-size: 1.125rem
  font-weight: 700
  background: var(--grey-99)
  border: thin solid var(--grey-60)
  border-radius: 6.25em
  color: var(--white)
  display: inline-flex
  justify-content: center
  align-items: center
  gap: 0.625rem

  span
    font-size: 1.125rem
    font-weight: 700

  &:disabled
    opacity: 0.7
    cursor: not-allowed

  &.full-width
    display: flex
    width: 100%

  &.link
    color: var(--white) !important
    &.background-green
      color: var(--black) !important

  &.background-green
    background: var(--green)
    color: var(--black)

    .ant-spin-dot-item
      background-color: var(--black)
