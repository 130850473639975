
// spacing
.mb-1
  margin-bottom: 0.625rem // 10px

.mb-2
  margin-bottom: 1.25rem // 20px

.mb-3
  margin-bottom: 1.875rem // 30px

.mb-4
  margin-bottom: 2.5rem // 40px

.mb-5
  margin-bottom: 3.125rem // 50px

.mb-6
  margin-bottom: 3.75rem // 60px

.mb-7
  margin-bottom: 4.375rem // 70px

.mb-8
  margin-bottom: 5rem // 80px

.mb-9
  margin-bottom: 5.625rem // 90px

.mb-10
  margin-bottom: 6.25rem // 100px

.me-auto
  margin: 0 auto
