.first-of-baseball
  margin-bottom: 3.125rem

  .slick-prev
    background: url('/images/icon/arrow-left.svg') no-repeat
    background-position: center
    background-size: cover
    left: 0.5rem
    z-index: 2

    &::before
      content: ''

  .slick-next
    background: url('/images/icon/arrow-right.svg') no-repeat
    background-position: center
    background-size: cover
    right: 0.5rem
    z-index: 2

    &::before
      content: ''

  .exhibition-content
    margin-top: 2.5rem
    display: flex
    justify-content: space-between
    align-items: flex-start
    gap: 1.8rem

    .block
      width: 50%

    .mobile-img
      display: none
  
  .slider-desk
    img
      cursor: pointer

  @media screen and ( max-width: 640px )
    margin-bottom: 1rem

    .exhibition-content
      flex-direction: column-reverse
      gap: 0

      .mobile-img
        display: block

      .block
        width: 100%

        &.left
          .slider-desk
            display: none
