.osamu-chris
  *
    font-size: 0.8125rem

  .content
    max-width: 704px
    padding: 1.375rem 0 5rem 0
    margin: 0 auto

  .title
    font-size: 1.125rem
    font-weight: 700

  .fb-image
    display: block
    margin: 0 auto
    margin-bottom: 3.75rem

  .nft-detail
    display: flex
    align-items: stretch
    gap: 2rem
    margin-bottom: 2.5rem

    .left-thumbnail
      min-width: 336px

    .right-detail
      width: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: flex-start

    .thumbnail
      background: url('/images/osamu-chris/nft.jpeg') no-repeat
      background-position: center
      background-size: cover
      border-top: thin solid var(--border)
      border-bottom: thin solid var(--border)

      .display
        width: 100%
        height: 100%
        padding-top: 141%

    button
      width: 100%
      text-transform: uppercase
      background: var(--black)
      padding: 0.625rem 0.312rem
      color: var(--white)

      &:disabled
        background: grey

    .btn-bid
      display: flex
      justify-content: space-between
      align-items: center
      gap: 1rem

      .btn-bar
        width: 2.5rem
        height: 2.5rem
        flex-shrink: 0
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center

        span
          display: block
          width: 24px
          height: 1px
          background: var(--white)

    .bid-price
      padding: 0.312rem 0.625rem
      border: thin solid var(--border)
      margin-bottom: 0.625rem
      font-weight: 700
      text-align: center

    .or
      text-align: center
      padding: 1.2rem 0
      position: relative

      &::before
        content: ''
        width: 100%
        height: 1px
        background: var(--border)
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)

      span
        display: inline-block
        padding: 0 0.625rem
        background: var(--white)
        position: relative

  .video
    margin-bottom: 2.5rem

    iframe
      width: 100%
      min-height: 400px
      border: none

      // .ytp-pause-overlay
      //   display: none !important

  .about-chris
    margin-bottom: 2.5rem

    .social
      svg
        font-size: 1.5rem
        margin-right: 0.625rem

  .presented-by
    .logo
      display: flex
      align-items: center
      gap: 1.6875rem

    img
      &:nth-child(2)
        max-width: 2rem

  // custom antd on page
  .ant-collapse
    .ant-collapse-header
      font-size: 1.125rem
      font-weight: 700
      padding: 0 2.5rem 0 0 !important
      display: inline-flex !important
      margin-bottom: 0.625rem
      text-decoration: underline

    .ant-collapse-content-box
      padding: 0 !important

  @media screen and ( max-width: 768px )
    .nft-detail
      flex-direction: column
      align-items: flex-start

      .left-thumbnail
        width: 100%
        max-width: 100%
        text-align: center

      .thumbnail
        max-width: 336px
        margin: 0 auto

  @media screen and ( max-width: 640px )
    .about-chris
      margin-bottom: 0

    .video
      iframe
        width: 100%
        min-height: 300px

  @media screen and ( max-width: 450px )
    .video
      iframe
        width: 100%
        min-height: 220px

  @media screen and ( max-width: 340px )
    .nft-detail
      .left-thumbnail
        min-width: inherit
