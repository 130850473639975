.page-404
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  .text-page-not-found
    color: var(--white)
    font-size: 2.5rem
