// typography
.text-left
  text-align: left

.text-right
  text-align: right

.text-center
  text-align: center

.text-justify
  text-align: justify

.text-capitalize
  text-transform: capitalize

  // font-weight
.fw-400
  font-weight: 400

.fw-500
  font-weight: 500

.fw-700
  font-weight: 700

// font-size
.fsz-18
  font-size: 1.125rem !important
