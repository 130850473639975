.input-page
  width: 100%
  min-height: 100vh
  background: var(--grey-99)
  color: var(--grey-50)
  display: flex
  justify-content: center
  align-items: center

  .input-otp
    margin: 2rem 0
    div
      div
        display: block !important

        input
          width: 16% !important
          min-width: 2.25rem
          font-size: 1.25rem
          padding: 0.5rem
          border-radius: 1.5rem
          color: var(--green)

  .content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding-top: 1rem

  .auth-logo
    margin-bottom: 3.437rem

  .form-content
    padding: 1.5rem
    border: 2px solid var(--grey-80)
    border-radius: 2rem
    overflow: hidden
    max-width: 400px

    .header-title
      margin-bottom: 1rem

  .form-input
    .error-message
      text-align: left
      margin-top: 0.312rem

    .item-select
      position: relative
      select
        width: 100%
        min-height: 2.3rem
        padding: 1rem
        background: var(--grey-80)
        color: var(--white)
        border: none
        border-radius: 1rem
        cursor: pointer
        appearance: none
        option
          height: 2rem
      img
        position: absolute
        top: 1.5rem
        right: 1rem

    .input-item
      margin-bottom: 1rem

      &.toggle-pass
        position: relative

        img
          position: absolute
          top: 1.35rem
          right: 1rem
          cursor: pointer

      input
        color: var(--white)

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

  .curate-inc
    font-size: 0.75rem
    color: var(--grey-70)

  @media screen and ( max-width: 640px )
    .form-content
      padding: 1.875rem 1.25rem
